import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import activityManager from 'reducers'
import sagas from './sagas'
import {
  signalrMiddleware,
  registerSignalREventListeners
} from './signalrMiddleware'
import { registerConnectivityHelper } from '../utils/connectivity'

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const loggerMiddleware = createLogger()
  const store = createStore(
    activityManager,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(
      thunkMiddleware,
      loggerMiddleware,
      signalrMiddleware,
      sagaMiddleware
    )
  )

  registerSignalREventListeners(store)
  registerConnectivityHelper(store)

  sagaMiddleware.run(sagas)

  return store
}

const store = configureStore()

export default store

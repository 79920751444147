/* eslint-disable no-param-reassign */
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import * as currentStoreActions from './current-store/actions'
import * as SignalRConstants from '../utils/signalrConstants'
import * as Constants from '../utils/constants'
import * as appActions from '../actions'
import { getStoreFromStorage } from '../utils/currentStore'
import { checkConnectionStatus } from '../utils/connectivity'

const signalRConnectionTimeout = 5000
const signalRMaxConnectionTimeout = 60000
let connectedToHub = false
let addedToGroup = false
let connectionAttemptCount = 0
let currentActivityReference = null
let currentSection = null

const getCurrentStoreCode = () => {
  const storeFromStorage = getStoreFromStorage()
  if (storeFromStorage) {
    return storeFromStorage.code
  }
  return null
}

const hubConnection = new HubConnectionBuilder()
  .withUrl(`${SignalRConstants.ACTIVITY_SIGNALR_HUB_URL}`)
  .configureLogging(LogLevel.Information)
  .build()

const addUserToStoreGroup = (conn, storeCode) => {
  conn.invoke(SignalRConstants.ADD_USER_TO_STORE_GROUP, storeCode)
  addedToGroup = true
}

const removeUserFromPreviousStoreGroup = (conn, state) => {
  const { currentStore } = state
  if (currentStore !== null) {
    conn.invoke(
      SignalRConstants.REMOVE_USER_FROM_STORE_GROUP,
      currentStore.code
    )
    addedToGroup = false
  }
}

const tryConnectToHub = (attempt = 1) => {
  if (connectedToHub === false) {
    connectionAttemptCount = attempt

    let reconnectTimeout = signalRConnectionTimeout * attempt
    reconnectTimeout =
      reconnectTimeout > signalRMaxConnectionTimeout
        ? signalRMaxConnectionTimeout
        : reconnectTimeout

    hubConnection
      .start()
      .then(() => {
        connectedToHub = true
        connectionAttemptCount = 0
        checkConnectionStatus()
        if (!addedToGroup && connectedToHub) {
          if (getCurrentStoreCode() !== null) {
            addUserToStoreGroup(hubConnection, getCurrentStoreCode())
          }
        }
      })
      .catch(() => {
        setTimeout(() => {
          tryConnectToHub((connectionAttemptCount += 1))
        }, reconnectTimeout)
      })
  }
}

tryConnectToHub()

hubConnection.onclose(async () => {
  connectedToHub = false
  addedToGroup = false
  checkConnectionStatus()
  await tryConnectToHub()
})

const fetchActivities = store => {
  if (currentSection !== null && currentSection.length > 0) {
    const filterState = store.getState().filter
    const activitiesState = store.getState().activities
    switch (currentSection) {
      case Constants.FILTER_PAGES.history:
        store.dispatch(
          appActions.requestHistoricActivities(
            getCurrentStoreCode(),
            filterState.pageNumber
          )
        )
        break
      case Constants.PLANNING_ROUTE: {
        switch (activitiesState.planningView) {
          case Constants.PLANNING_WEEK_VIEW:
            store.dispatch(
              appActions.requestActivitiesForWeek(activitiesState.weekInView)
            )
            break
          case Constants.PLANNING_PERIOD_VIEW:
            store.dispatch(
              appActions.requestActivitiesForPeriod(
                activitiesState.periodInView
              )
            )
            break
          default:
            break
        }
        break
      }
      case Constants.FILTER_PAGES.risk:
        store.dispatch(
          appActions.requestRiskActivities(
            getCurrentStoreCode(),
            activitiesState.riskPageNumber,
            filterState.applied
          )
        )
        break
      default:
        store.dispatch(
          appActions.requestActivities(getCurrentStoreCode(), currentSection)
        )
        break
    }
  }
}

const inListView = () => currentSection !== null

export const registerSignalREventListeners = store => {
  hubConnection.on(SignalRConstants.EVENT_ACTIVITY_STARTED, startedDetails => {
    if (inListView()) {
      fetchActivities(store)
    } else if (currentActivityReference === startedDetails.id) {
      store.dispatch(appActions.fetchActivity(startedDetails.id))
    }
  })

  hubConnection.on(
    SignalRConstants.EVENT_ACTIVITY_COMPLETED,
    completedDetails => {
      if (inListView()) {
        fetchActivities(store)
      } else if (currentActivityReference === completedDetails.id) {
        const appState = store.getState()
        if (
          appState.activities.currentActivity &&
          appState.activities.currentActivity.type ===
            Constants.ACTIVITY_TYPE_SURVEY &&
          appState.azure.currentUserId !== completedDetails.completedById
        ) {
          store.dispatch(appActions.currentActivityHasBeenCompleted())
        } else {
          store.dispatch(appActions.fetchActivity(completedDetails.id))
        }
      }
    }
  )

  hubConnection.on(
    SignalRConstants.EVENT_ACTIVITY_ASSIGNED,
    assignmentDetails => {
      if (inListView()) {
        fetchActivities(store)
      } else if (currentActivityReference === assignmentDetails.id) {
        store.dispatch(appActions.fetchActivity(assignmentDetails.id))
        store.dispatch(
          appActions.fetchColleagueAssignmentsRequest(assignmentDetails.id)
        )
        store.dispatch(
          appActions.fetchAssignmentEventsPage(assignmentDetails.id)
        )
      }
      const appState = store.getState()
      const userId = appState.azure.currentUserId

      if (
        [assignmentDetails.assigneeId, assignmentDetails.assignerId].some(
          x => x === userId
        )
      ) {
        store.dispatch(
          appActions.createNotification(
            assignmentDetails.notificationMessage,
            'success'
          )
        )
      }
    }
  )

  hubConnection.on(
    SignalRConstants.EVENT_ACTIVITY_UNASSIGNED,
    unassignmentDetails => {
      if (inListView()) {
        fetchActivities(store)
      } else if (currentActivityReference === unassignmentDetails.id) {
        store.dispatch(appActions.fetchActivity(unassignmentDetails.id))
        store.dispatch(
          appActions.fetchColleagueAssignmentsRequest(unassignmentDetails.id)
        )
        store.dispatch(
          appActions.fetchAssignmentEventsPage(unassignmentDetails.id)
        )
      }
    }
  )

  hubConnection.on(SignalRConstants.EVENT_ACTIVITY_UPDATED, activity => {
    if (inListView()) {
      fetchActivities(store)
    } else if (currentActivityReference === activity.id) {
      store.dispatch(appActions.fetchActivity(activity.id))
    }
  })

  hubConnection.on(SignalRConstants.EVENT_ACTIVITY_DELETED, activity => {
    if (inListView()) {
      fetchActivities(store)
    } else if (currentActivityReference === activity.id) {
      store.dispatch(appActions.fetchActivity(activity.id))
    }
  })

  hubConnection.on(
    SignalRConstants.EVENT_ACTIVITIES_DELETED,
    activityReferences => {
      if (inListView()) {
        fetchActivities(store)
      } else {
        const affectsCurrentActivity = activityReferences.includes(
          currentActivityReference
        )
        if (affectsCurrentActivity) {
          store.dispatch(appActions.fetchActivity(currentActivityReference))
        }
      }
    }
  )

  hubConnection.on(SignalRConstants.EVENT_ACTIVITIES_ADDED, () => {
    if (inListView()) {
      fetchActivities(store)
    }
  })

  hubConnection.on(
    SignalRConstants.EVENT_ACTIVITY_COMPLETION_UNDONE,
    activity => {
      if (inListView()) {
        fetchActivities(store)
      } else if (currentActivityReference === activity.id) {
        store.dispatch(appActions.fetchActivity(activity.id))
      }
    }
  )
}

export const signalrMiddleware = store => next => action => {
  switch (action.type) {
    case currentStoreActions.actions.SET_CURRENT_STORE:
      if (connectedToHub) {
        removeUserFromPreviousStoreGroup(hubConnection, store.getState())
        addUserToStoreGroup(hubConnection, action.store.code)
      }
      break
    case appActions.ACTIVITIES_REQUEST:
    case appActions.ACTIVITIES_FOR_WEEK_REQUEST:
    case appActions.ACTIVITIES_FOR_PERIOD_REQUEST:
    case appActions.ACTIVITIES_HISTORY_REQUEST:
    case appActions.RISK_ACTIVITIES_REQUEST:
      currentActivityReference = null
      currentSection = action.section
      break
    case appActions.ACTIVITY_REQUEST:
      currentActivityReference = action.activityId
      currentSection = null
      break
    default:
      break
  }
  if (getCurrentStoreCode() !== null && !addedToGroup && connectedToHub) {
    addUserToStoreGroup(hubConnection, getCurrentStoreCode())
  }
  return next(action)
}

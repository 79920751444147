import { call, put, all } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import ActivitiesAPI from 'api'
import * as actions from 'actions'
import {
  setSurveyQuestion,
  makeFormData,
  parseSurveyQuestionsResponses,
  parseSurveyQuestionsResponsesWithImageQuestions
} from '@jsainsburyplc/activity-survey'
import {
  QUESTION_TYPE_IMAGE,
  MAX_IMAGE_UPLOAD_RETRYS,
  RETRY_TIMEOUT,
  COMPLETE_ACTIVITY_ERROR_MESSAGE
} from 'utils/constants'
import { getStoreFromStorage } from 'utils/currentStore'
import { groupBy } from 'lodash'
import store from '../configureStore'
import {
  ACTIVITY_TYPE_SURVEY,
  FILE_UPLOAD_SURVEY_TYPE
} from '../../utils/constants'
import { parseResponseFormData } from '../../utils/helpers'

function* fetchColleagueAssignments({ refId }) {
  try {
    const colleagueAssignments = yield call(
      ActivitiesAPI.fetchColleagueAssignments,
      refId
    )
    yield put(actions.fetchColleagueAssignmentsSuccess(colleagueAssignments))
  } catch (err) {
    yield put(actions.fetchColleagueAssignmentsFailure())
  }
}

export function* requestActivityMedia({ activityId }) {
  try {
    const media = yield call(ActivitiesAPI.fetchActivityMedia, activityId)
    yield put(actions.requestActivityMediaSuccess(media))
  } catch (err) {
    yield put(actions.requestActivityMediaFailure(err))
  }
}

export function* fetchActivityQuestions({ activityId }) {
  try {
    const activityQuestions = yield call(
      ActivitiesAPI.getActivityQuestions,
      activityId
    )
    const activityQuestionUpdate = question =>
      store.dispatch(actions.updateActivityQuestion(question))
    const uiQuestions = activityQuestions.map(question =>
      setSurveyQuestion(
        question.id,
        question.type,
        question.number,
        false,
        false,
        question.title,
        question.isRequired,
        question.options,
        activityQuestionUpdate
      )
    )
    yield put(actions.receiveActivityQuestions(uiQuestions))
    yield put(
      actions.toggleImageConfirmationModalPopup(
        activityQuestions.filter(x => x.type === QUESTION_TYPE_IMAGE).length > 0
      )
    )
  } catch (err) {
    yield put(actions.requestActivityQuestionsFailure())
  }
}
export function* requestActivityQuestionFileUpload({ activityId, fileData }) {
  try {
    yield put(actions.showUploadingActivitySpinner(true))
    const activityQuestionsResponse = yield call(
      ActivitiesAPI.submitActivityQuestionFileData,
      activityId,
      fileData
    )
    yield put(
      actions.receiveActivityQuestionFileResponse(activityQuestionsResponse)
    )
  } catch (err) {
    yield put(actions.showUploadingActivitySpinner(false))
    yield put(actions.requestCompleteActivityFileQuestionsFailure(err))
  }
}

function* uploadActivityQuestionFiles(activityId, imageQuestions) {
  yield put(actions.showUploadingActivitySpinner(true))

  const storeCode = getStoreFromStorage().code
  const imageResponses = yield all(
    imageQuestions.map(question =>
      // eslint-disable-next-line func-names
      call(function* () {
        try {
          return yield call(
            ActivitiesAPI.submitActivityQuestionFileData,
            activityId,
            makeFormData([question], storeCode)
          )
        } catch (err) {
          yield put(actions.showRetryingActivitySpinner(true))
          let totalRetrys = 0
          let error = err
          while (totalRetrys <= MAX_IMAGE_UPLOAD_RETRYS) {
            totalRetrys += 1
            yield call(delay, RETRY_TIMEOUT)
            try {
              return yield call(
                ActivitiesAPI.submitActivityQuestionFileData,
                activityId,
                makeFormData([question])
              )
            } catch (e) {
              error = e
            }
          }
          throw error
        }
      })
    )
  )
  return parseSurveyQuestionsResponsesWithImageQuestions(
    imageQuestions,
    imageResponses
  )
}

function* uploadSurveyResponseFiles(activityId, responses) {
  const storeCode = getStoreFromStorage().code
  const uploadedResponses = yield all(
    responses.map(response =>
      // eslint-disable-next-line func-names
      call(function* () {
        try {
          return yield call(
            ActivitiesAPI.submitResponseFileData,
            activityId,
            parseResponseFormData([response], storeCode)
          )
        } catch (err) {
          yield put(actions.showRetryingActivitySpinner(true))
          let totalRetries = 0
          let error = err
          while (totalRetries <= MAX_IMAGE_UPLOAD_RETRYS) {
            totalRetries += 1
            yield call(delay, RETRY_TIMEOUT)
            try {
              return yield call(
                ActivitiesAPI.submitResponseFileData,
                activityId,
                parseResponseFormData([response], storeCode)
              )
            } catch (e) {
              error = e
            }
          }
          throw error
        }
      })
    )
  )

  const flattenedFileResponses = [].concat(...uploadedResponses)

  return Object.keys(groupBy(flattenedFileResponses, 'id')).map(x => ({
    questionId: x,
    value: flattenedFileResponses.filter(y => y.id === x).map(z => z.response)
  }))
}

export function* requestCompleteActivity({
  activityId,
  activityType,
  response
}) {
  let resolvedResponse = response
  try {
    if (resolvedResponse !== null) {
      if (activityType === ACTIVITY_TYPE_SURVEY) {
        const standardResponses = response.filter(
          x => x.type !== FILE_UPLOAD_SURVEY_TYPE
        )
        const fileUploadResponses = response.filter(
          x => x.type === FILE_UPLOAD_SURVEY_TYPE
        )

        const uploadedFileResponses = yield uploadSurveyResponseFiles(
          activityId,
          fileUploadResponses
        )

        resolvedResponse = standardResponses
          .map(x => ({ questionId: x.questionId, value: x.value }))
          .concat(uploadedFileResponses)
      }
    }
    yield put(actions.completeActivity(activityId, resolvedResponse))
  } catch (err) {
    yield put(actions.requestCompleteActivityFailure())
    yield put(
      actions.createNotification(COMPLETE_ACTIVITY_ERROR_MESSAGE, 'danger')
    )
  }
}

export function* requestCompleteActivityQuestions({ activityId, responses }) {
  const textResponses = parseSurveyQuestionsResponses(
    responses.filter(q => q.questionType !== QUESTION_TYPE_IMAGE)
  )
  const imageQuestions = responses.filter(
    q => q.questionType === QUESTION_TYPE_IMAGE
  )
  try {
    const imageResponses = yield uploadActivityQuestionFiles(
      activityId,
      imageQuestions
    )
    const questionResponses = textResponses
      .concat(imageResponses)
      .sort((a, b) => a.number > b.number)
    if (
      questionResponses &&
      questionResponses.length > 0 &&
      (imageResponses.every(response => !response.error) ||
        imageQuestions.length === 0)
    ) {
      yield call(
        ActivitiesAPI.submitActivityQuestionResponses,
        activityId,
        questionResponses
      )
      yield put(actions.requestCompleteActivityQuestionsSuccess())
      yield put(actions.completeActivity(activityId))
      yield put(actions.toggleCompletedModalPopup(true))
    } else {
      yield put(
        actions.requestCompleteActivityQuestionsFailure(
          responses.find(response => response.error)
        )
      )
    }
  } catch (err) {
    yield put(actions.requestCompleteActivityQuestionsFailure(err))
    yield put(
      actions.createNotification(COMPLETE_ACTIVITY_ERROR_MESSAGE, 'danger')
    )
  }
}

export function* setInstanceViewed({ referenceId }) {
  try {
    yield call(ActivitiesAPI.setInstanceViewed, referenceId)
    yield put(actions.setInstanceViewedSuccess())
  } catch (err) {
    yield put(actions.setInstanceViewedFailure(err))
  }
}

export function* changeInstanceDueDate({ referenceId, dueDate }) {
  try {
    yield call(ActivitiesAPI.changeInstanceDueDate, referenceId, dueDate)
    yield put(actions.changeInstanceDueDateSuccess())
  } catch (err) {
    yield put(actions.changeInstanceDueDateFailure(err))
  }
}

export function* fetchAuditEvents({ instanceId }) {
  try {
    const audit = yield call(
      ActivitiesAPI.getAuditActivityEvents,
      instanceId,
      false
    )
    yield put(actions.fetchAuditEventSuccess(audit))
  } catch (err) {
    yield put(actions.fetchAuditEventFailure())
  }
}

export function* createAdhocAction({ request }) {
  try {
    const mediaUploadResponse = yield all(
      request.mediaAttachments.map(x =>
        call(
          ActivitiesAPI.submitMediaAttachmentsFileData,
          x,
          getStoreFromStorage().code
        )
      )
    )
    yield put(actions.uploadMediaSuccess(mediaUploadResponse))
    request.mediaAttachments = mediaUploadResponse
    const response = yield call(ActivitiesAPI.createAdhocTask, request)
    yield put(actions.createActionSuccess(response))
  } catch (err) {
    yield put(actions.uploadMediaFailure(err))
    yield put(actions.createActionFailure(err))
  }
}

export default fetchColleagueAssignments

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import 'scss/index.scss'
import history from './configureHistory'
import App from './components/App'
import store from './store/configureStore'
import AuthWrapper from './authentication/authWrapper'

const render = () => {
  ReactDOM.render(
    <AuthWrapper>
      <Router history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </AuthWrapper>,
    document.getElementById('root')
  )
}

render()
